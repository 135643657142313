#HeadNav {
  height: 50px;
  color: #fff;

  .nav-wrap {
    width: 1200px;
    margin: 0 auto;
    height: 50px;
    box-sizing: border-box;

    .nav-logo-wrap {
      display: inline-block;
      font-size: 26px;
      width: 26px;
      //height:50px;
      vertical-align: top;
      margin-left: 12px;
      margin-top: 4px;
    }

    .nav-list-wrap {
      width: 1160px;
      display: inline-block;
      height: 50px;
      text-align: right;

      .link {
        color: #ffffff;
      }

      .ant-menu {
        color: #fff;
        background: transparent;
        display: inline-block;
        height: 50px;
      }

      .ant-menu-horizontal {
        border-color: transparent;
      }

      .ant-menu-horizontal .ant-menu-item, .ant-menu-horizontal .ant-menu-submenu {
        margin-top: 0;
      }

      .ant-menu-horizontal {
        line-height: 47px;
      }
    }
  }
}
@primary-color: #1DA57A;