#FooterNav {
  height: 100%;
  width: 100%;
  color: #939393;
  margin-top: auto;

  .link {
    display: inline-block;
    text-decoration: none;
    height: 20px;
    line-height: 20px;
    margin: 0px 0px 0px 5px;
    color: #939393;
  }
}
@primary-color: #1DA57A;