#defaultLayout {

  .sider {
    background: #ffffff;
  }

  .sider-card {
    color: red;
  }
}
@primary-color: #1DA57A;